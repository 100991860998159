// Redux

export const REDUCER_KEY_AUTH = 'auth';
export const ACTION_NAME_LOGIN_USER = 'loginUser';
export const ACTION_NAME_REFRESH_USER_TOKEN = 'refreshToken';
export const ACTION_NAME_LOGOUT_USER = 'logoutUser';
export const ACTION_NAME_SIGNUP_USER = 'signupUser';
export const ACTION_NAME_FORGOT_PASSWORD = 'forgotPassword';
export const ACTION_NAME_RECOVER_PASSWORD = 'recoverPassword';
export const ACTION_NAME_IMPERSONATE = 'impersonate';
export const ACTION_NAME_AGENCY_IMPERSONATE = 'agencyImpersonate';
export const ACTION_NAME_VERIFICATION_CODE = 'verificationCode';
export const ACTION_NAME_CHECK_LINK = 'checkLink';

export const REDUCER_KEY_COMMON = 'common';
export const ACTION_NAME_FETCH_LEVELS_LIST = 'fetchLevelsList';
export const ACTION_NAME_FETCH_COMMON_COMPANIES_LIST = 'fetchCommonCompaniesList';
export const ACTION_NAME_FETCH_COMMON_LANGUAGES_LIST = 'fetchCommonLanguagesList';
export const ACTION_NAME_FETCH_COMMON_CATEGORIES_LIST = 'fetchCommonCategoriesList';
export const ACTION_NAME_FETCH_COMMON_WEEKLY_BILLINGS_STATUSES = 'fetchWeeklyBillingsStatuses';
export const ACTION_NAME_FETCH_COMMON_ORDERS_STATUSES = 'fetchOrdersStatuses';
export const ACTION_NAME_FETCH_COMMON_PAYOUTS_STATUSES = 'fetchPayoutsStatuses';
export const ACTION_NAME_FETCH_COMMON_PURCHASE_ORDER_INVOICE_STATUSES = 'fetchPurchaseOrderInvoiceStatuses';
export const ACTION_NAME_FETCH_COMMON_PURCHASE_ORDER_STATUSES = 'fetchPurchaseOrderStatuses';
export const ACTION_NAME_FETCH_COMMON_BUSINESS_RULE_APPLIES_TO = 'fetchBusinessRuleAppliesTo';
export const ACTION_NAME_FETCH_COMMON_ORDER_CHANGE_TYPES = 'fetchOrderChangeTypes';
export const ACTION_NAME_FETCH_COMMON_CUSTOMER_CHANGE_TYPES = 'fetchCustomerChangeTypes';
export const ACTION_NAME_FETCH_COMMON_SPECIALIST_CHANGE_TYPES = 'fetchSpecialistChangeTypes';
export const ACTION_NAME_FETCH_COMMON_PRODUCT_ACTIONS = 'fetchProductActions';
export const ACTION_NAME_FETCH_COMMON_AGENCY_ACTIONS = 'fetchAgencyActions';
export const ACTION_NAME_FETCH_COMMON_BUSINESS_RULE_FIELDS = 'fetchBusinessRuleFields';
export const ACTION_NAME_FETCH_COMMON_BUSINESS_RULE_ACTION_TYPES = 'fetchBusinessRuleActionTypes';

export const REDUCER_KEY_ORDERS = 'orders';
export const ACTION_NAME_FETCH_ORDERS_LIST = 'fetchOrdersList';
export const ACTION_NAME_FETCH_ORDER_ITEM = 'fetchOrderItem';
export const ACTION_NAME_CHANGE_STATUS_ORDER_ITEM = 'changeStatusOrderItem';
export const ACTION_NAME_DELETE_ORDER_ITEM = 'deleteOrderItem';
export const ACTION_NAME_CREATE_ORDER = 'createOrder';
export const ACTION_NAME_EDITE_ORDER_ITEM = 'editOrderItem';
export const ACTION_NAME_EDITE_ORDER_ITEM_END_DATE = 'editOrderItemEndDate';

export const REDUCER_KEY_CHANGE_LOGS = 'changeLogs';
export const ACTION_NAME_FETCH_ORDER_ITEM_LOGS = 'fetchOrderItemLogs';

export const REDUCER_KEY_CONSULTANTS = 'consultants';
export const ACTION_NAME_FETCH_CONSULTANTS_LIST = 'fetchConsultantsList';
export const ACTION_NAME_FETCH_MATCHED_CONSULTANTS_LIST = 'fetchMatchedConsultantsList';
export const ACTION_NAME_FETCH_CONSULTANT_ITEM = 'fetchConsultantItem';
export const ACTION_NAME_UPDATE_CONSULTANT_ITEM = 'updateConsultantItem';
export const ACTION_NAME_DOWNLOAD_CV = 'fetchCV';
export const ACTION_NAME_FETCH_CONSULTANT_ITEM_AVAILABILITY = 'fetchConsultantItemAvailability';
export const ACTION_NAME_FETCH_CONSULTANT_ITEM_EXPERIENCE = 'fetchConsultantItemExperience';
export const ACTION_NAME_APPROVE_CONSULTANT = 'approveConsultant';
export const ACTION_NAME_DECLINE_CONSULTANT = 'declineConsultant';
export const ACTION_NAME_MARK_INTERNAL_CONSULTANT = 'markInternalConsultant';
export const ACTION_NAME_FETCH_CONSULTANT_ITEM_PRODUCTS = 'fetchConsultantItemProducts';
export const ACTION_NAME_FETCH_CONSULTANT_ITEM_LANGUAGES = 'fetchConsultantItemLanguages';
export const ACTION_NAME_CHANGE_CONSULTANT_ACTIVE_STATUS = 'changeConsultantActiveStatus';
export const ACTION_NAME_CHANGE_CONSULTANT_CALL_SCHEDULED_STATUS = 'changeConsultantCallScheduledStatus';
export const ACTION_NAME_CHANGE_CONSULTANT_EMAIL = 'changeConsultantEmail';
export const ACTION_NAME_FETCH_CONSULTANTS_SUMMARY_DATA = 'fetchConsultantsSummaryData';
export const ACTION_NAME_FETCH_APPROVED_CONSULTANTS_SUMMARY_DATA = 'fetchApprovedConsultantsSummaryData';
export const ACTION_NAME_FETCH_CONSULTANT_HASH_CODE = 'fetchConsultantHashCode';

export const REDUCER_KEY_REVIEWS = 'reviews';
export const ACTION_NAME_FETCH_REVIEWS_LIST = 'fetchReviewsList';
export const ACTION_NAME_FETCH_REVIEW_ITEM = 'fetchReviewItem';
export const ACTION_NAME_APPROVE_REVIEW_ITEM = 'approveReviewItem';
export const ACTION_NAME_DECLINE_REVIEW_ITEM = 'declineReviewItem';

export const REDUCER_KEY_CUSTOMERS = 'customers';
export const ACTION_NAME_FETCH_CUSTOMERS_LIST = 'fetchCustomersList';
export const ACTION_NAME_CHANGE_CUSTOMER_ACTIVE_STATUS = 'changeCustomerActiveStatus';
export const ACTION_NAME_CHANGE_CUSTOMER_EMAIL = 'changeCustomerEmail';

export const REDUCER_KEY_TAGS = 'tags';
export const ACTION_NAME_FETCH_TAGS_LIST = 'fetchTagsList';
export const ACTION_NAME_ADD_TAG_ITEM = 'addTagItem';
export const ACTION_NAME_EDIT_TAG_ITEM = 'editTagItem';

export const REDUCER_KEY_COMPANIES = 'companies';
export const ACTION_NAME_FETCH_COMPANIES_LIST = 'fetchCompaniesList';
export const ACTION_NAME_FETCH_COMPANY_ITEM = 'fetchCompanyItem';
export const ACTION_NAME_ADD_COMPANY_ITEM = 'addCompanyItem';
export const ACTION_NAME_EDIT_COMPANY_ITEM = 'editCompanyItem';
export const ACTION_NAME_ADD_COMPANY_ITEM_LOGO = 'addCompanyItemLogo';
export const ACTION_NAME_EDIT_COMPANY_ITEM_ENTERPRISE_STATUS = 'editCompanyItemEnterpriseStatus';
export const ACTION_NAME_CHANGE_COMPANY_TEAM_ACTIVE_STATUS = 'changeCompanyTeamActiveStatus';
export const ACTION_NAME_CHANGE_COMPANY_EMAIL = 'changeCompanyEmail';

export const REDUCER_KEY_AGENCIES = 'agencies';
export const ACTION_NAME_FETCH_AGENCIES_LIST = 'fetchAgenciesList';
export const ACTION_NAME_FETCH_AGENCY_ITEM = 'fetchAgencyItem';
export const ACTION_NAME_ADD_AGENCY_ITEM = 'addAgencyItem';
export const ACTION_NAME_EDIT_AGENCY_ITEM = 'editAgencyItem';
export const ACTION_NAME_ADD_AGENCY_ITEM_LOGO = 'addAgencyItemLogo';
export const ACTION_NAME_CHANGE_AGENCY_TEAM_ACTIVE_STATUS = 'changeAgencyTeamActiveStatus';

export const REDUCER_KEY_SNACK_NOTIFICATIONS = 'snackNotifications';

export const REDUCER_KEY_FEEDBACKS = 'feedbacks';
export const ACTION_NAME_FETCH_FEEDBACKS_LIST = 'fetchFeedbacksList';
export const ACTION_NAME_FETCH_FEEDBACK_ITEM = 'fetchFeedbackItem';
export const ACTION_NAME_EDIT_FEEDBACK_ITEM = 'editFeedbackItem';

export const REDUCER_KEY_PRODUCTS = 'products';
export const ACTION_NAME_FETCH_PRODUCTS_LIST = 'fetchProductsList';
export const ACTION_NAME_FETCH_PRODUCT_AVAILABILITY = 'fetchProductAvailability';
export const ACTION_NAME_FETCH_PRODUCT_ITEM = 'fetchProductItem';
export const ACTION_NAME_EDIT_PRODUCT_ITEM = 'editProductItem';
export const ACTION_NAME_CREATE_PRODUCT_ITEM = 'createProductItem';
export const UPDATE_WORK_TYPES = 'updateWorkTypes';
export const CREATE_WORK_TYPES = 'createWorkTypes';
export const UPDATE_PRODUCT_MODULES = 'updateProductModules';
export const CREATE_PRODUCT_MODULES = 'createProductModules';
export const ACTION_NAME_FETCH_PRODUCTS_SUMMARY_DATA = 'fetchProductsSummaryData';

export const REDUCER_KEY_PURCHASE_ORDERS = 'purchaseOrders';
export const ACTION_NAME_PURCHASE_ORDERS_LIST = 'fetchPurchaseOrdersList';
export const ACTION_NAME_ADD_PURCHASE_ORDER_ITEM = 'addPurchaseOrderItem';
export const ACTION_NAME_UPDATE_PURCHASE_ORDER_ITEM = 'updatePurchaseOrderItem';
export const ACTION_NAME_GET_PURCHASE_ORDER_FILE = 'downloadPurchaseOrderFile';
export const ACTION_NAME_FETCH_PURCHASE_ORDER_FILE_BY_ID = 'fetchPurchaseOrderFileById';
export const ACTION_NAME_FETCH_PURCHASE_ARCHIVE = 'fetchPurchaseArchive';

export const REDUCER_KEY_ENTERPRISE_INVOICING = 'enterpriseInvoicing';
export const ACTION_NAME_FETCH_WEEKLY_BILLINGS_LIST = 'fetchWeeklyBillingsList';
export const ACTION_NAME_FETCH_WEEKLY_BILLINGS_FILTERED_LIST = 'fetchWeeklyBillingsFilteredList';
export const ACTION_NAME_APPROVE_WEEKLY_BILLING_ITEM = 'approveWeeklyBillingItem';
export const ACTION_NAME_DISPUTE_WEEKLY_BILLING_ITEM = 'disputeWeeklyBillingItem';
export const ACTION_NAME_FETCH_INVOICES_LIST = 'fetchInvoicesList';
export const ACTION_NAME_ADD_INVOICE_ITEM = 'addInvoiceItem';
export const ACTION_NAME_FETCH_INVOICE_ITEM = 'fetchInvoiceItem';
export const ACTION_NAME_EDIT_INVOICE_ITEM = 'editInvoiceItem';
export const ACTION_NAME_CREATE_DRAFT_INVOICE_ITEM = 'createDraftInvoiceItem';
export const ACTION_NAME_FETCH_WEEKLY_BILLINGS_STAT = 'fetchWeeklyBillingsStat';

export const REDUCER_KEY_AGENCY_PAYOUTS = 'agencyPayouts';
export const ACTION_NAME_FETCH_PAYOUTS_LIST = 'fetchPayoutsList';
export const ACTION_NAME_TRANSFER_PAYOUT_ITEM = 'transferPayoutItem';
export const ACTION_NAME_CREATE_PAYOUT = 'createPayout';
export const ACTION_NAME_UPDATE_PAYOUT = 'updatePayout';

export const REDUCER_KEY_LEADS = 'leads';
export const ACTION_NAME_FETCH_LEAD_LIST = 'fetchLeadList';
export const ACTION_NAME_FETCH_LEAD_SOURCE_AGENCY_LIST = 'fetchLeadSourceAgencies';
export const ACTION_NAME_FETCH_LEAD_ITEM = 'fetchLeadItem';
export const ACTION_NAME_ADD_LEAD_ITEM = 'addLeadItem';

export const REDUCER_KEY_LEAD_SOURCES = 'leadSource';
export const ACTION_NAME_FETCH_LEAD_SOURCE_LIST = 'fetchLeadSourceList';

export const REDUCER_KEY_LEAD_MANAGERS = 'leadManagers';
export const ACTION_NAME_FETCH_LEAD_MANAGER_LIST = 'fetchLeadManagerList';
export const ACTION_NAME_FETCH_LEAD_MANAGER_ITEM = 'fetchLeadManagerItem';
export const ACTION_NAME_CREATE_LEAD_MANAGER_ITEM = 'createLeadManagerItem';
export const ACTION_NAME_REMOVE_LEAD_MANAGER_ITEM = 'removeLeadManagerItem';
export const ACTION_NAME_UPDATE_LEAD_MANAGER_ITEM = 'updateLeadManagerItem';
export const ACTION_NAME_CREATE_PRE_ORDER_FROM_LEAD = 'createPreOrderFromLead';

export const REDUCER_KEY_SERVICE_CATALOG = 'service-catalog-entries';
export const ACTION_NAME_FETCH_SERVICE_CATALOG_LIST = 'fetchServiceCatalogEntriesList';
export const ACTION_NAME_FETCH_SERVICE_CATALOG_ITEM = 'fetchServiceCatalogEntryItem';
export const ACTION_NAME_FETCH_SERVICE_CATALOG_ITEMS_FROM_LEAD = 'fetchServiceCatalogEntriesFromLeadId';
export const ACTION_NAME_CREATE_SERVICE_CATALOG_ITEM = 'createServiceCatalogEntryItem';
export const ACTION_NAME_REMOVE_SERVICE_CATALOG_ITEM = 'removeServiceCatalogEntryItem';

export const REDUCER_KEY_BUSINESS_RULES = 'businessRules';
export const ACTION_NAME_BUSINESS_RULES_LIST = 'fetchBusinessRulesList';
export const ACTION_NAME_ADD_BUSINESS_RULE_ITEM = 'addBusinessRuleItem';
export const ACTION_NAME_UPDATE_BUSINESS_RULE_ITEM = 'updateBusinessRuleItem';
export const ACTION_NAME_DELETE_BUSINESS_RULE_ITEM = 'deleteBusinessRuleItem';
export const ACTION_NAME_UPDATE_BUSINESS_RULE_ACTION = 'updateBusinessRuleAction';
export const ACTION_NAME_DELETE_BUSINESS_RULE_ACTION = 'deleteBusinessRuleAction';

export const REDUCER_KEY_USERS = 'users';
export const ACTION_NAME_USERS_LIST = 'fetchUsersList';
export const ACTION_NAME_INVITE_USER = 'inviteUser';
export const ACTION_NAME_DEACTIVATE_USER = 'deactivateUser';
export const ACTION_NAME_REMOVE_USER = 'removeUser';
export const ACTION_NAME_CHANGE_ROLE_USER = 'changeUserRole';
export const ACTION_NAME_RESEND_INVITE = 'resendUserInvite';

export const REDUCER_KEY_INFLUENCERS = 'influencers';
export const ACTION_INFLUENCERS_LIST = 'fetchInfluencersList';
export const ACTION_INVITE_INFLUENCER = 'inviteInfluencer';
